.common-table {
  width: 100%;
  border-collapse: collapse;
  thead {
    th {
      text-align: left;
      padding: 14px 8px;
      background: #fbfcfe;
      box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
      margin-bottom: 8px;
      color: #616161;
      font-size: 0.8rem;
      text-transform: uppercase;
    }
  }
  tbody {
    background: #fbfcfe;
    td {
      background: #fbfcfe;
      box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
      padding: 18px 8px;
      color: #616161;
      font-size: 0.8rem;
      &.nested-table {
        padding: 0.5rem 0 0 0.5rem;
      }
    }
  }
}
