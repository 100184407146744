@import "../../styles/variables.scss";

.codegroups-partner-code-modal .modal {
  width: auto;
}

.codegroups-file-upload {
  box-sizing: border-box;
  color: var(--text-color-text-secondary, #616161);
  width: max-content;
}

.codegroups-page-content {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  gap: 16px;
  .codegroups-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    button:hover {
      background-color: #004ba0;
    }
  }
  .bulk-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(243, 247, 253, 1);
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    border-radius: 4px;
    .codegroup-selected-count {
      font-weight: 700;
      font-size: 12px;
    }
    .bulk-action-buttons {
      display: flex;
      gap: 1rem;
    }
  }
}

.codegroups-search-filter-container {
  display: flex;
  gap: 16px;
  width: 100%;
}

.codegroups-view-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.codegroups-input-search {
  border-top: 0;
  border-bottom: 1px solid #3a3a3a;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  &:focus {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  &:focus-within {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  &:active {
    border-top: 0;
    border-bottom: 1px solid #005cbc;
    border-right: 0;
    border-left: 0;
  }
  input {
    &:focus {
      &::placeholder {
        color: #005cbc;
      }
    }
    font-size: 14px;
  }
}

.codegroups-card {
  .card-header {
    display: none;
  }
  .card-footer {
    display: none;
  }
  .card-content {
    align-items: initial;
  }
}

.codegroups-card .card-footer {
  display: none;
}

.codegroups-card {
  .partner-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .status {
      width: fit-content;
      font-size: 10px;
      border-radius: 4px;
      padding: 2px 4px;
      &.draft {
        background-color: rgb(97, 97, 97);
      }
      &.inactive {
        background-color: rgb(156, 156, 156);
      }
      &.scheduled {
        background-color: rgb(255, 204, 73);
        color: rgb(58, 58, 58);
      }
      &.completed {
        background-color: rgb(179, 218, 203);
        color: rgb(58, 58, 58);
      }
      &.ended {
        background-color: rgb(102, 126, 136);
      }
    }
  }

  .partner-info {
    display: flex;
    gap: 10px;
    .meta-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  }

  .button-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 0px;
    &.deletable {
      .button-group button:last-child {
        color: #d40020;
      }
    }
  }
}

.codegroups-status {
  width: fit-content;
  font-size: 10px;
  border-radius: 4px;
  padding: 2px 4px;
  &.draft {
    background-color: rgb(97, 97, 97);
  }
  &.scheduled {
    background-color: rgb(255, 204, 73);
    color: rgb(58, 58, 58);
  }
  &.completed {
    background-color: rgb(179, 218, 203);
    color: rgb(58, 58, 58);
  }
  &.ended {
    background-color: rgb(102, 126, 136);
  }
}

.codegroups-button-group-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  &.deletable {
    .button-group button:last-child {
      color: #d40020;
    }
  }
}

.codegroups-button-group-wrapper {
  &.deletable {
    .button-group button:last-child {
      color: #d40020;
    }
  }
}

.codegroups-datatable {
  .header {
    display: none;
  }
  .footer {
    display: none;
  }
}

.codegroups-grid-container {
  display: grid;
  margin-top: 16px;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.codegroups-filter-styles {
  gap: 8px;

  .button {
    padding: 4px 8px;
    margin: 0;
    white-space: nowrap;
  }

  .debounced-filters {
    display: flex;
    align-items: center;
  }

  .nonDebounced-filters {
    .horizontal-layoutItem-0 {
      flex: 2 2;
    }

    .horizontal-layoutItem-1 {
      flex: 2 2;
      margin-top: 24px;
    }

    .horizontal-layoutItem-2 {
      flex: 3.6 2;
    }
    .horizontal-layoutItem-3 {
      flex: 3.6 2;
    }
    .horizontal-layoutItem-4 {
      margin-top: 10px;
    }
    .horizontal-layoutItem-5 {
      margin-top: 10px;
    }
    .range-filter {
      width: max-content;
      margin-top: 12px;
    }
  }
  .nonDebounced-filters .horizontal-layoutItem-0 .MuiSelect-select > span,
  .nonDebounced-filters .horizontal-layoutItem-1 .MuiSelect-select > span {
    color: #1976d2 !important;
  }
  .horizontal-layoutItem-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-item {
    margin-top: 1rem;
  }

  .dropdown-container {
    height: 230px;
    z-index: 100;

    border-color: $light-blue;

    .dropdown-list {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      max-height: none;

      .list-item {
        width: 100%;
      }

      tbody {
        width: 100%;
        display: block;

        .\$spacing-p01 {
          padding-bottom: 10px;
          margin-bottom: 5px;
        }

        div {
          .badge {
            background: $light-blue;
          }
        }
      }

      a {
        color: $dark-gray;
      }
    }
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: $light-blue;
        border-radius: 15px;
      }

      .MuiInputBase-input {
        color: $primary-color;

        &::placeholder {
          color: $primary-color;
          opacity: 1;
        }
      }

      .MuiInputAdornment-root {
        .MuiButtonBase-root {
          .MuiSvgIcon-root {
            color: $primary-color;
          }
        }
      }
    }
  }

  .multiple-select-wrap {
    width: 100%;

    .dropdown-title {
      width: 100%;
      margin-bottom: 2px;

      .dropdown-display-text {
        display: none;
      }

      span.icon {
        display: none;
      }
    }

    .dropdown-input-row {
      width: 100%;

      .icon-container {
        .icon {
          color: $primary-color;
        }

        .badge {
          background: $light-blue;
        }
      }

      .dropdown-parent {
        border-radius: 15px;

        &:-moz-placeholder {
          color: $primary-color;
          opacity: 1;
        }

        &::-webkit-input-placeholder {
          color: $primary-color;
          opacity: 1;
        }

        width: 100%;
        border-color: $light-blue;
        color: $primary-color;
      }
    }
  }
}

.code-group-listing-toast {
  z-index: 1;
}
